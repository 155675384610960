<template>
  <div id="newsStoryContent">
    <!-- pic80F132352C607719F62AA03E2DD68373.jpg -->
    <img id="Image1" class="rounded img-large" src="@/assets/news-network/lu-launches-on-10-26-2010.jpg">
    <p id="Text1">
      The highly anticipated launch of LEGO® Universe is nearly here!
      <br><br>
      The LEGO Company will proudly launch our first ever massively multiplayer online game (MMOG)
      on <b>October 26, 2010</b>!
      <br><br>
      This autumn, join the battle to save Imagination! Become a custom LEGO Minifigure, then blastoff
      for endless worlds of creative adventure!
      <br><br>
      Blaze around in your own spaceship amassing a handsome supply of LEGO bricks as you master fun new
      skills—like racing custom LEGO racecars or teaming up to smash the dark Maelstrom’s fearsome creatures!
      <br><br>
      When you touchdown on your own LEGO Universe world, you can use your bricks to build anything you
      can imagine, then bring your creations to life!
      <br><br>
      If you want to create a car that glides through the sky, simply apply a flying behavior to your model,
      and up it goes! You can create highly imaginative living LEGO worlds!
      <br><br>
      And, if you pre-order LEGO Universe today, you’ll get exclusive early access to the game, a
      limited edition space explorer Minifigure and more! Discover all the pre-ordering details
      <router-link href="/campaign/preorder">here</router-link>.
    </p>
  </div>
</template>
